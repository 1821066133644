import {
  faCalendar,
  faComments,
  faCreditCard,
  faFileLines,
  faGear,
  faList,
  faTag,
  faUser,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface HeaderLink {
  title: string;
  type: string;
  url: string;
  icon: IconDefinition;
  indent?: boolean; // the '?' makes it optional
}

export const UmbracoSiteLinks = [
  {
    title: "About Us",
    type: "link",
    url: "/what-is-rentmy/",
  },
  {
    title: "How it Works",
    type: "link",
    url: "/how-it-works/",
  },
  {
    title: "Guarantee",
    type: "link",
    url: "/guarantee/",
  },
  {
    title: "Help",
    type: "link",
    url: "https://zen.rentmy.com/hc/en-gb",
  },
];

export const accountPageLinks: HeaderLink[] = [
  {
    title: "Dashboard",
    type: "link",
    url: "/account/dashboard",
    icon: faUser,
    indent: false,
  },
  {
    title: "Messages",
    type: "link",
    url: "/account/messages",
    icon: faComments,
    indent: false,
  },
  {
    title: "Rentals",
    type: "link",
    url: "/account/my-rentals",
    icon: faList,
    indent: false,
  },
  {
    title: "My Items",
    type: "link",
    url: "/account/my-items",
    icon: faTag,
    indent: false,
  },
  {
    title: "My Bookings",
    type: "link",
    url: "/account/my-bookings",
    icon: faCalendar,
    indent: true,
  },
  {
    title: "My Claims",
    type: "link",
    url: "/account/my-claims",
    icon: faFileLines,
    indent: true,
  },
  {
    title: "Profile",
    type: "link",
    url: "/account/profile",
    icon: faUserCog,
    indent: false,
  },
  {
    title: "Verification",
    type: "link",
    url: "/account/verification",
    icon: faCreditCard,
    indent: true,
  },
  {
    title: "Settings",
    type: "link",
    url: "/account/settings",
    icon: faGear,
    indent: true,
  },
];

export const unauthorisedAccountPageLinks: HeaderLink[] = [
  {
    title: "Login/Signup",
    type: "link",
    url: "/account/profile",
    icon: faUser,
    indent: false,
  },
  // {
  //     'title': 'Create a listing',
  //     'type': 'link',
  //     'url': '/item/listing',
  //     'icon': faTag,
  // }
];

// export const headerLinksHTML = UmbracoSiteLinks.map(
//   (headerLinkObject, index) => (
//     <li key={index}>
//       <a className="desktop-header-links" href={headerLinkObject.url}>
//         {headerLinkObject.title}
//       </a>
//     </li>
//   )
// );
