import { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Range } from 'react-date-range';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet"; // Import Helmet

import {
  BookingClient,
  BookingCreateDto,
  BookingPricingDto,
  CurrencyIsoCode,
  IConfig,
  ItemClient,
  ItemPublicDto,
  Location,
  PricePeriod,
  ReferralClient,
  ReferralDetailedDto,
  RentMyUserPublicSimpleDto,
  StripeCheckoutRedirects,
  UserClient,
  VoucherClient,
} from '../../api/rentMyApi';
import LocationCard from '../item-detailed/LocationCard';
import ItemSnippet from '../common/ItemSnippet';
import PoliciesSnippetWithModal from '../item-detailed/PoliciesSnippetWithModal';
import { DisplayPriceComponents } from '../item-detailed/DisplayPriceComponents';
import DateRangePickerPricePeriodDisabling from '../common/DateRangePickerPricePeriodDisabling';
import moment from 'moment';
import BasicModal from '../common/BasicModal';
import { getStandardPricePeriod } from '../item/ItemListingConstantsAndDefaults';
import { dateStringFormat } from '../common/DateFormatting';
import BackButton from '../common/BackButton';
import ModalWithImage from '../common/ModalWithImage';
import { numberTo12HourTime } from '../../constants/HourlyClockOptions';
import { beginCheckoutAnalytics, sendAddPaymentInfoEvent, sendBeginCheckoutEvent } from '../../api/analytics';
import { useUserContext } from '../../context/UserContext';
import { UserLocationForm } from '../common/UserLocationForm';
import { useEnv } from '../../context/env.context';
import { Typography } from '@mui/material';
import { PromoCodeInput } from '../account/PromoCodeInput';

export function ItemBookingConfirmation() {
  const enableInsurance = process.env.REACT_APP_SHOW_INSURANCE && /true/.test(process.env.REACT_APP_SHOW_INSURANCE);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const hourlyStart = 'hourlyStart';
  const hourlyEnd = 'hourlyEnd';
  const { user, refetchCurrentUser } = useUserContext();

  const [itemClient] = useState<ItemClient>(new ItemClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT));
  const [bookingClient, setBookingClient] = useState<BookingClient>(
    new BookingClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT),
  );
  const [referralClient, setReferralClient] = useState<ReferralClient>(
    new ReferralClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT),
  );
  const [userClient] = useState(new UserClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT));
  const [voucherClient, setVoucherClient] = useState(
    new VoucherClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT),
  );
  const [itemDetails, setItemDetails] = useState<ItemPublicDto>();
  const [referrals, setReferrals] = useState<ReferralDetailedDto[]>([]);
  const [appliedVouchers, setAppliedVouchers] = useState<string[]>([]);
  const [showDateInput, setShowDateInput] = useState(false);

  const [insuranceRequired, setInsuranceRequired] = useState<boolean>(false);
  const [insuranceAccepted, setInsuranceAccepted] = useState<boolean>(false);
  const [insuranceApplied, setInsuranceApplied] = useState<boolean>(false);

  const [isInsured, setIsInsured] = useState<boolean>(false);
  const [insurancePlanID, setInsurancePlanID] = useState('');
  const [hasInsurancePlan, setHasInsurancePlan] = useState<boolean>(false);

  const [userHasLocation, setUserHasLocation] = useState<boolean>(false);
  const [userLocationModalOpen, setUserLocationModalOpen] = useState<boolean>(false);
  const [userLocationData, setUserLocationData] = useState<Location>(
    user.location ??
    new Location({
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: 'United Kingdom',
      postcode: '',
    } as Location),
  );
  const [isUserLocationProcessing, setIsUserLocationProcessing] = useState<boolean>(false);

  const [bookingPriceDetails, setBookingPriceDetails] = useState<BookingPricingDto>();

  const [total, setTotal] = useState<number>(0);

  const [itemId, setItemId] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const [userOfRentedItemInfo, setUserOfRentedItemInfo] = useState<RentMyUserPublicSimpleDto>();

  const [modalStateRentersGuarantee, setModalStateRentersGuarantee] = useState(false);
  const [modalStateConfirmation, setModalStateConfirmation] = useState(false);

  const startDateConst = 'start-date';
  const endDateConst = 'end-date';
  const cancelId = 'cancel-id';

  const getReferralCodeIds = () => {
    // Assuming the referrals state contains the valid discount codes
    return referrals.map((referral) => referral.code).filter((code) => code !== undefined) as string[];
  };

  // https://github.com/hypeserver/react-date-range/issues/330#issuecomment-802601417
  const [dateRange, setDateRange] = useState<Range>({
    startDate: undefined,
    endDate: new Date(''),
    key: 'selection',
  });

  const [paymentInfo, setPaymentInfo] = useState({
    cardNumber: '',
    expiryDate: '',
    cvc: '',
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (
      userLocationData &&
      userLocationData.addressLine1 &&
      userLocationData.city &&
      userLocationData.country &&
      userLocationData.postcode
    ) {
      setUserHasLocation(true);
    } else {
      setUserHasLocation(false);
    }
  }, [userLocationData]);

  useEffect(() => {
    async function authoriseBookingAndReferralClient() {
      const token = await getAccessTokenSilently();
      const newBookingClient = new BookingClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      const newReferralClient = new ReferralClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      const newVoucherClient = new VoucherClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);

      setBookingClient(newBookingClient);
      setReferralClient(newReferralClient);
      // setVoucherClient(newVoucherClient);
      const cancelIdStr = params.get(cancelId);
      if (cancelIdStr) {
        newBookingClient
          .cancel(cancelIdStr)
          .then((res) => {
          })
          .catch((err) => {
          });
      }
    }

    authoriseBookingAndReferralClient();
  }, []);

  // useEffect(() => {
  //   console.log("discount Total", discountTotal);
  // }, [discountTotal]);

  useEffect(() => {
    const itemIdStr = params.get('id');

    if (itemIdStr) {
      setItemId(itemIdStr);
    } else {
      return navigate('/', { replace: true });
    }
    const startDateStr = params.get(startDateConst) || '';
    const endDateStr = params.get(endDateConst) || '';

    const compareDatesByDateSection = 'date';
    const startDateMoment: moment.Moment = moment(startDateStr);
    const endDateMoment: moment.Moment = moment(endDateStr);

    if (
      startDateMoment.isValid() &&
      endDateMoment.isValid() &&
      startDateMoment.isSameOrBefore(endDateMoment, compareDatesByDateSection)
    ) {
      const startDate: Date = startDateMoment.toDate();
      const endDate: Date = endDateMoment.toDate();

      setDateRange({
        startDate,
        endDate,
        key: 'selection',
      });
    }
  }, []);

  useEffect(() => {
    if (total === 0) {
      return;
    }
    // console.log("Begin checkout Event");
    const accountID = user.id;
    const itemID = itemId;
    const itemInCart = itemDetails?.name;
    const cartValue = itemDetails?.itemValue;

    sendBeginCheckoutEvent(accountID, itemID, itemInCart, cartValue, total);
    beginCheckoutAnalytics({}); // Old pixel
  }, [itemDetails, total]);

  //
  // useEffect(() => {
  //   console.log("Voucher Client!!");
  //
  //   voucherClient.voucher2().then((response) => {
  //     console.log("Voucher Response");
  //     console.log(response);
  //   });
  // }, [voucherClient]);

  useEffect(() => {
    if (itemId === '') {
      return;
    }

    itemClient
      .detailed24(itemId)
      .then((response: ItemPublicDto) => {
        if (response.id == null) {
          navigate('/not-found');
          // setIsItemDetailsExist(false);
          return;
        }
        setItemDetails(response);
        setUserOfRentedItemInfo(response.user);

        // if (response?.user?.id) {
        //   userClient
        //     .user(response.user.id)
        //     .then((a: RentMyUserPublicSimpleDto) => {
        //       setUserOfRentedItemInfo(a);
        //     });
        // }
      })
      .catch((error: any) => {
        toast.error(t('item_detailed_loading_error'));
        // console.error("Item details retrieving error", error);
      });
  }, [itemId]);

  useEffect(() => {
    if (
      enableInsurance &&
      itemDetails &&
      itemDetails.insurancePlans &&
      itemDetails.insurancePlans?.[0]?.minimumPremium &&
      itemDetails.itemValue &&
      itemDetails?.itemValue >= itemDetails?.insurancePlans?.[0]?.minimumPremium
    ) {
      // if insurance is not required
      setHasInsurancePlan(itemDetails.insurancePlans.length > 0);
      setInsuranceRequired(itemDetails.requireInsurance);
      setIsInsured(true);
      setInsurancePlanID(itemDetails?.insurancePlans?.[0]?.id);
    } else {
      setHasInsurancePlan(false);
      setInsuranceRequired(false);
      setIsInsured(false);
    }
  }, [itemDetails]);

  // TODO: fetch if item is available for these dates
  const onDateRangeChange = (newDateRange: Range) => {
    const params = new URLSearchParams(location.search);

    params.set(startDateConst, moment(newDateRange.startDate).format(dateStringFormat));
    params.set(endDateConst, moment(newDateRange.endDate).format(dateStringFormat));

    const newUrl = `${location.pathname}?${params.toString()}`;

    setDateRange(newDateRange);
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
  };

  const handleSaveUserLocation = async (localLocationData: Location) => {
    setIsUserLocationProcessing(true);
    const token = await getAccessTokenSilently();

    setUserLocationData(localLocationData);
    try {
      await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).changeLocationPOST2(undefined, localLocationData);

      await refetchCurrentUser();
    } catch (error) {
      toast.error(t('item_booking_confirmation_location_update_error'));
    }

    setIsUserLocationProcessing(false);
    setUserLocationModalOpen(false);
  };

  const isDateRangeValid = () => {
    return dateRange.startDate != null && dateRange.endDate != null && dateRange.startDate < dateRange.endDate;
  };

    useEffect(() => {
    const fetchBookingPrices = async () => {
      var vouchersList: string[] = [];

      if (appliedVouchers.length > 0) {
          // Create an array of promises
          const voucherPromises = appliedVouchers.map((Vouch) =>
              voucherClient
                  .fromCode(Vouch)
                  .then((response) => {
                      if (response.id) {
                          vouchersList.push(response.id);
                      }
                  })
                  .catch((error) => {
                      toast.error(error.message);
                      handleRemoveVoucher(Vouch);
                  })
          );

          // Wait for all promises to resolve
          await Promise.all(voucherPromises);
      }

      const bookingCreateDto = new BookingCreateDto({
        active: true,
        itemId: itemDetails!.id,
        startingDate: dateRange!.startDate ?? new Date(),
        endingDate: dateRange!.endDate ?? new Date(),
        isHourly: false,
          currency: CurrencyIsoCode.GBP,
          vouchers: vouchersList,
        insurancePlanId: insuranceApplied ? insurancePlanID : undefined,
        referralCode: undefined, //logic should be updated when multiple vouchers can be applied
      });
        bookingClient
            .pricingCheck(bookingCreateDto)
            .then((response) => {
                setBookingPriceDetails(response);

                if (response.responses != null && response.responses.length > 0) {
                    for (let i = 0; i < response.responses.length; i++) {
                        var x = response.responses[i];
                        toast.error(x);

                        var voucher = x.split(" ")[1];
                        handleRemoveVoucher(voucher);
                    }
                }
            })
            .catch((error) => {
                if (error.response === 'Discount Code could not be found') {
                    toast.error('Invalid Discount Code');
                    setAppliedVouchers([]);
                } else {
                    toast.error(error.message);
                }
            });
    };

    if (itemDetails && isDateRangeValid()) {
      fetchBookingPrices();
    }
  }, [itemDetails, dateRange, insuranceApplied, appliedVouchers]);

    const handleRemoveVoucher = (voucher: string) => {
        setAppliedVouchers((oldValues) =>
            oldValues.filter((item) => item !== voucher)
        );
    };

  const onConfirm = async () => {
    if (!userHasLocation) {
      setUserLocationModalOpen(true);
      return;
    }
    if (!dateRange.startDate || !dateRange.endDate) {
      return toast.warning(t('item_booking_confirmation_select_start_end'));
    }
    if (!moment(dateRange.startDate).isSameOrBefore(moment(dateRange.endDate))) {
      return toast.warning(t('item_booking_confirmation_select_end'));
    }

    if (!isMinMaxBookingRangeValid()) {
      return;
    }

    setIsProcessing(true);

    const isHourly = false;
    const rentalLocationUrl = '/account/my-rentals';

    // const newlyAddedReferralCodes = getReferralCodeIds();

    console.log("END DATE", dateRange.endDate)

      var vouchersList: string[] = [];

      if (appliedVouchers.length > 0) {
          // Create an array of promises
          const voucherPromises = appliedVouchers.map((Vouch) =>
              voucherClient
                  .fromCode(Vouch)
                  .then((response) => {
                      if (response.id) {
                          vouchersList.push(response.id);
                      }
                  })
                  .catch((error) => {
                      toast.error(error.message);
                  })
          );

          // Wait for all promises to resolve
          await Promise.all(voucherPromises);
      }

    const payload = new BookingCreateDto({
        vouchers: vouchersList,
      active: true,
      itemId,
      // startingDate: moment(dateRange.startDate).startOf("day").toDate(),
      // endingDate: moment.utc(dateRange.endDate).endOf("day").toDate(),
      startingDate: moment(dateRange.startDate).startOf('day').set({hour:9}).toDate(),
      // startingDate: moment(dateRange.startDate).toDate(),
      endingDate: moment(dateRange.endDate).startOf('day').set({hour:9}).toDate(),
      // endingDate: moment.utc(dateRange.endDate).toDate(),
      isHourly,
      currency: CurrencyIsoCode.GBP,
      insurancePlanId: enableInsurance && insuranceAccepted && insuranceApplied ? insurancePlanID : undefined,
      referralCode: undefined,
    });

    // console.log("addPaymentInfoAnalytics")
    const accountID = user.id;
    const dateObject = new Date();
    const date = dateObject.toLocaleDateString();
    const time = dateObject.toLocaleTimeString();
    const isMobile = /Mobile/i.test(navigator.userAgent);
    const device = isMobile ? 'Mobile' : 'Desktop';
    const successResponseParams = itemDetails?.instantBooking
      ? '&view=booking&handover=false'
      : '&view=booking&handover=false&showConfirmationToaster=true';

    await sendAddPaymentInfoEvent(accountID, date, time, device);

    console.log("Payload")
    console.log(payload)

    bookingClient
      .createPOST(true, payload)
      .then((response) => {
        const successResponse =
          window.location.origin + rentalLocationUrl + '?id=' + response.id + successResponseParams;
        const cancelResponse = window.location.href + '&cancel-id=' + response.id;
        const stripeCheckoutRedirects = new StripeCheckoutRedirects({
          cancel: cancelResponse,
          success: successResponse,
        });
        bookingClient
          .checkout(response.id, undefined, stripeCheckoutRedirects)
          .then((response) => {
            window.location.href = response;
            setIsProcessing(false);
          })
          .catch(async () => {
            setIsProcessing(false);
            toast.error('There was an error making this booking, please contact support');
            await bookingClient.cancel(response.id);
          });
      })
      .catch((e) => {
        toast.error(e.response);
        setIsProcessing(false);
      });
  };

  const isMinMaxBookingRangeValid = (): boolean => {
    if (!itemDetails || !itemDetails.priceInfo || !itemDetails.priceInfo.periods) {
      toast.error('no_price_periods');
      return false;
    }

    const pricePeriod = itemDetails?.priceInfo?.periods?.[0]; // Assuming there is only one price period

    const numberOfDays = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');

    if (pricePeriod.minimumBookingDays && numberOfDays < pricePeriod.minimumBookingDays) {
      toast.error(`You cannot book less than ${pricePeriod.minimumBookingDays} days`);
      return false;
    }

    if (pricePeriod.maximumBookingDays && numberOfDays > pricePeriod.maximumBookingDays) {
      toast.error(`You cannot book more than ${pricePeriod.maximumBookingDays} days`);
      return false;
    }

    return true;
  };

  const getCheckinAndReturnTime = (propertyName: string): number | null => {
    if (!itemDetails || !itemDetails.priceInfo || !itemDetails.priceInfo.periods) {
      return null;
    }

    const dailyStandardPricePeriod: PricePeriod | undefined = getStandardPricePeriod(itemDetails.priceInfo.periods);

    if (!dailyStandardPricePeriod) {
      return null;
    }

    return (dailyStandardPricePeriod.price as any)[propertyName] ?? null;
  };

  const goBack = (): void => {
    // let url = "/item-detailed?id=" + itemId;
    //
    // if (params.has(startDateConst)) {
    //   url += `&${startDateConst}=${params.get(startDateConst)}`;
    // }
    //
    // if (params.has(endDateConst)) {
    //   url += `&${endDateConst}=${params.get(endDateConst)}`;
    // }

    navigate(-1);
  };

  const introSection = () => {
    return (
      <div className="item-booking-intro-section">
        <BackButton onClick={goBack} />
        <h2 className="mb-0">{t('item_booking_confirmation_header')}</h2>
      </div>
    );
  };

  const dateSection = () => {
    return (
      <Card>
        <CardBody>
          <div className="flex align-items-center justify-content-space-between mb-2">
          <h2>{t("item_booking_confirmation_booking_details")}</h2>
            <Button
                outline
                color="primary"
                className="flex-right"
                onClick={() => setShowDateInput(!showDateInput)}
            >
              {t("item_booking_confirmation_change_dates")}
            </Button>
          </div>
          <Row>
            {showDateInput &&
                itemDetails &&
                itemDetails.priceInfo &&
                itemDetails.priceInfo.periods && (
                    <DateRangePickerPricePeriodDisabling
                        pricePeriods={itemDetails?.priceInfo.periods}
                        dateRangeState={dateRange}
                        setDateRangeState={onDateRangeChange}
                        months={1}
                        showClearButton={false}
                        itemId={itemDetails.id}
                    />
                )}
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <label className="form-control-label" htmlFor="rental-start">
                {t('item_booking_confirmation_date_start')}
              </label>
              <input
                  disabled
                  id="rental-start"
                  className="form-control"
                  value={moment(dateRange.startDate).format("DD-MM-yyyy")}
                  onChange={(e) =>
                      setDateRange({...dateRange, startDate: moment(e.target.value, "DD-MM-yyyy").toDate()})
                  }
              />
            </Col>
            <Col xs={12} md={6}>
              <label className="form-control-label" htmlFor="rental-end">
                {t('item_booking_confirmation_date_end')}
              </label>
              <input
                  disabled
                  id="rental-end"
                  className="form-control"
                  value={moment(dateRange.endDate).format("DD-MM-yyyy")}
                  onChange={(e) =>
                      setDateRange({...dateRange, endDate: moment(e.target.value, "DD-MM-yyyy").toDate()})
                  }
              />
            </Col>

            {numberTo12HourTime(getCheckinAndReturnTime(hourlyStart)) &&
                numberTo12HourTime(getCheckinAndReturnTime(hourlyEnd)) && (
                    <>
                      <Col md={6}>
                        <label htmlFor="rental-pickup">
                          {t("item_booking_confirmation_check_in_time")}
                        </label>
                        <Input
                            id="rental-pickup"
                            name="select"
                            type="text"
                            defaultValue={numberTo12HourTime(
                                getCheckinAndReturnTime(hourlyStart)
                            )}
                            className={"form-control"}
                            disabled
                        ></Input>
                      </Col>
                      <Col md={6}>
                        <label htmlFor="rental-checkout">
                          {t("item_booking_confirmation_check_out_time")}
                        </label>
                        <Input
                            id="rental-checkout"
                            name="select"
                            type="text"
                            defaultValue={numberTo12HourTime(
                                getCheckinAndReturnTime(hourlyEnd)
                            )}
                            className={"form-control"}
                            disabled
                        ></Input>
                      </Col>
                      <Col className="mt-3">
                        <p className="extra-info-small">
                          {t("item_booking_confirmation_pick_up_info")}
                        </p>
                      </Col>
                    </>
                )}
          </Row>
          <p
              className="padding-top-10"
              style={{fontSize: "0.7em"}}
          >
            { moment(dateRange.startDate).format("ll") ===  moment(dateRange.endDate).format("ll") ? "Please select the date you wish to return the item" : "Unless otherwise agreed with the Sharer, you must return the item by 9am on " +
                moment(dateRange.endDate).format("ll")}
          </p>
        </CardBody>
      </Card>
    );
  };

  const submitSection = () => {
    const isBookable =
        !enableInsurance ||
        (!(insuranceRequired && !insuranceAccepted) &&
            !(insuranceApplied && !insuranceAccepted));
    return (
        <Card>
          <CardBody>
            {!itemDetails?.instantBooking && (
            <Typography variant="subtitle2" className="mb-1">
              {t('non_instant_booking_confirmation_explanation_subtext')}
            </Typography>
          )}
          <button
            onClick={() => onConfirm()}
            type="button"
            className="btn btn-primary btn-lg btn-block"
            disabled={!isBookable}
          >
            {isProcessing ? (
              <div className="loading-spinner-small"></div>
            ) : !isBookable ? (
              t('item_booking_confirmation_pay_button_insurance_required')
            ) : itemDetails?.instantBooking ? (
              t('item_booking_confirmation_pay_button')
            ) : (
              'Confirm Booking Request'
            )}
          </button>
          <br />
            {enableInsurance && <Typography variant="subtitle2" fontSize="0.65em" textAlign="center">
            ProtectMy Ltd is an Appointed Representative of Innovative Risk Labs Ltd Authorised and Regulated by the Financial Conduct Authority FRN 609155
          </Typography>}
        </CardBody>
      </Card>
    );
  };

  const rentersGuaranteeBody = (): JSX.Element => {
    return (
      <>
        <p>{t('item_booking_confirmation_renters_guarantee_1')}</p>
        <p className="rentmy-semi-bold">{t('item_booking_confirmation_renters_guarantee_2')}</p>
        <p>{t('item_booking_confirmation_renters_guarantee_3')}</p>
        <p>{t('item_booking_confirmation_renters_guarantee_4')}</p>
        <p className="rentmy-semi-bold">{t('item_booking_confirmation_renters_guarantee_5')}</p>
        <p>{t('item_booking_confirmation_renters_guarantee_6')}</p>
      </>
    );
  };

  return (
    <Container className="mt-4">
      <Helmet>
        <title>{itemDetails ? `Book your ${itemDetails.name} rental ` : 'Loading...'}</title> {/* Step 2: Add dynamic title */}
      </Helmet>
      {introSection()}
      <ItemSnippet
        wantInCard={true}
        icon={{
          userSetup: {
            userId: userOfRentedItemInfo?.id,
            userProfilePicSrc: userOfRentedItemInfo?.profileImage?.compressedPath
              ? userOfRentedItemInfo?.profileImage.compressedPath
              : 'assets/img/profile-pic-placeholder.webp',
          },
          iconElement: (
            <img
              src={
                itemDetails?.images && itemDetails?.images?.[0] && itemDetails?.images?.[0].path
                  ? itemDetails?.images?.[0].path
                  : 'assets/img/placeholder.jpg'
              }
              alt={itemDetails?.images?.[0].name}
            />
          ),
          wantIconToBeCircle: true,
        }}
        text={{
          title: itemDetails?.name ?? '',
          text: `${t('item_detailed_shared_by')} ${userOfRentedItemInfo?.name}`,
          secondaryText: ` `,
        }}
        ratingSetup={{
          overallAverage: itemDetails?.overallAverage ?? 0,
          reviewCount: itemDetails?.reviewCount ?? 0,
        }}
      />
      <Row>
        <Col xs={12}>{dateSection()}</Col>
        <Col xs={12}>
          <LocationCard
            latitude={itemDetails?.approximateLocation?.latitude ?? 0}
            longitude={itemDetails?.approximateLocation?.longitude ?? 0}
            city={itemDetails?.approximateLocation?.city ?? ''}
            country={itemDetails?.approximateLocation?.country ?? ''}
            wantCircle={true}
          />
        </Col>
      </Row>

      {itemDetails && (
        <PoliciesSnippetWithModal
          cancellationPolicy={itemDetails.cancellationPolicy}
          insurance={itemDetails.insurancePlans && itemDetails.insurancePlans.length > 0}
          hideSmallText={true}
        />
      )}

      {/* Vouchers */}
      {(
        <Card>
          <CardBody>
            <Col style={{ paddingLeft: 0 }}>
              <h2 className="mb-0">{t('discount_code')}</h2>
              <p className="small grey">If it's your first time renting ask for a referral code from the owner or a freind for £10 off!</p>
            </Col>
            <Col className="p-0">
              <PromoCodeInput
                appliedVouchers={appliedVouchers}
                setAppliedVouchers={setAppliedVouchers}
                totalPrice={total}
              />
            </Col>
          </CardBody>
        </Card>
      )}

      {itemDetails && itemDetails.priceInfo && itemDetails.priceInfo.periods && bookingPriceDetails && (
        <DisplayPriceComponents
          itemDetails={itemDetails}
          dateRangeState={dateRange}
          setDateRangeState={setDateRange}
          wantDateSelectionAndMultiDayDiscount={false}
          wantPriceBreakdown={true}
          showDiscounts={true}
          onTotalChange={setTotal}
          discounts={appliedVouchers}
          discountTotal={bookingPriceDetails.discount}
          discountPrice={total}
          isInsured={isInsured}
          insuranceAccepted={insuranceAccepted}
          onInsuranceAcceptedChange={setInsuranceAccepted}
          insuranceRequired={insuranceRequired}
          hasInsurancePlan={hasInsurancePlan}
          showInsurance={true}
          showIPT={true}
          insuranceApplied={insuranceApplied}
          setInsuranceApplied={setInsuranceApplied}
          confirmationPage={true}
          bookingPriceDetails={bookingPriceDetails}
        />
      )}
      {/*<DisplayPrice itemDetails={itemDetails!} insuranceApplied={insuranceApplied} dateRangeState={dateRange} bookingPriceDetails={bookingPriceDetails} onInsuranceAcceptedChange={setInsuranceAccepted}/>*/}

      {submitSection()}
      <BasicModal
        modalTitle={t('item_booking_confirmation_renters_guarantee_title')}
        modalBody={rentersGuaranteeBody()}
        modalState={modalStateRentersGuarantee}
        toggleModal={() => setModalStateRentersGuarantee(!modalStateRentersGuarantee)}
      />
      <BasicModal
        modalState={userLocationModalOpen}
        modalTitle={t('manage_profile_about_location')}
        toggleModal={() => setUserLocationModalOpen((prev) => !prev)}
        modalBody={
          <UserLocationForm
            locationData={userLocationData}
            handleSaveUserLocation={handleSaveUserLocation}
            isUserLocationProcessing={isUserLocationProcessing}
          />
        }
      />

      <ModalWithImage
        isOpen={modalStateConfirmation}
        onClose={() => setModalStateConfirmation(false)}
        isDefault={true}
        image={'assets/img/modal-placeholder-image.webp'}
        title={t('item_booking_confirmation_fake_payment_modal_title')}
        description={t('item_booking_confirmation_fake_payment_modal_desc')}
        defaultButtonText={t('modal_close')}
        onDefaultButtonClick={() => setModalStateConfirmation(false)}
        primaryButtonText={t('confirm')}
        onPrimaryButtonClick={() => {
          setModalStateConfirmation(false);
          onConfirm();
        }}
      />
    </Container>
  );
}